import React, { useEffect } from 'react';
import './App.css';
import RegistrationProgressBar from './components/assets/ProgressBar/progress-bar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// registration
const RegistrationForm = React.lazy(() => import("./components/assets/Registration/registration-form"));
const TermsAndConditions = React.lazy(() => import("./components/assets/Registration/terms-and-conditions"));
const ProvementForm = React.lazy(() => import("./components/assets/Registration/provement-form"));
// message
const CompleteMessage1 = React.lazy(() => import("./components/assets/Message/step1-complete-message"));
const CompleteMessage2 = React.lazy(() => import("./components/assets/Message/step2-complete-message"));

const AppContent = () => {
  const location = useLocation(); // Get current location (pathname)

  return (
    <main className='App relative'>
      <ToastContainer />
      
      {/* Only render the RegistrationProgressBar if we're not on the terms-and-conditions page */}
      {location.pathname !== '/terms-and-conditions' && <RegistrationProgressBar />}
      
      <Routes>
        <Route path="/" element={<RegistrationForm />} />
        <Route path="form-submission/:register_user_id" element={<RegistrationForm />} />
        <Route path="/terms-and-Conditions" element={<TermsAndConditions />} />
        <Route path="document-submission/:register_user_id" element={<ProvementForm />} />
        <Route path="step1-complete-message/:register_user_name" element={<CompleteMessage1 />} />
        <Route path="step2-complete-message/:registerUserName" element={<CompleteMessage2 />} />
      </Routes>
    </main>
  );
}

const App = () => {

  useEffect(() => {
    document.title = "SubZero - Registration Form";
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
